.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: 90px;
  border-bottom: 1px solid var(--line-dark);
  padding: 0 240px;
}

.ctn {
  display: flex;
  align-items: center;
}

.menu {
  display: flex;
  align-items: center;
  list-style-type: none;
}

.item {
  font-size: 18px;
  line-height: 34px;
  font-weight: 700;
  padding: 0 20px;
  position: relative;
}

.item div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.item:hover .subMenu {
  display: block;
}

.subMenu {
  display: none;
  list-style-type: none;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  padding: 16px 24px;
  border-radius: 16px;
  box-shadow: 0px -1px 12px rgba(255, 255, 255, 0.25);
  background: #161616;
}

.soulbound {
  color: #fff;
  background: var(--linear-primary);
  padding: 8px 40px;
  border-radius: 24px;
  height: 48px;
  font-weight: 700;
  border: none;
  cursor: pointer;
  font-size: 20px;
  line-height: 160%;
}

.btn {
  display: none;
  margin-left: 16px;
}

.addition {
  display: none;
}

@media only screen and (max-width: 768px) {
  .navbar {
    height: 54px;
    padding: 0 16px;
  }

  .image {
    height: 36px;
  }

  .menu {
    position: absolute;
    flex-direction: column;
    top: 100%;
    left: 0;
    right: 0;
    display: none;
    align-items: flex-start;
    background-color: #000;
    margin-top: 40px;
    height: calc(100vh - 54px);
    z-index: 5;
  }

  .item {
    margin-bottom: 24px;
  }

  .subMenu {
    position: relative;
    background-color: transparent;
    box-shadow: none;
    padding: 8px 16px;
  }

  .btn {
    display: block;
  }

  .addition {
    display: block;
  }

  .soulbound {
    padding: 10px 14px;
    font-size: 16px;
    line-height: 19px;
    height: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1440px) {
  .navbar {
    padding: 0 80px;
  }
}
