.footer {
  padding: 0 240px;
}

.ctn {
  margin-bottom: 120px;
}

.right {
  margin-inline: -30px;
}

.grid {
  margin-inline: 30px;
}

.grid ul {
  list-style-type: none;
}

.grid li,
.grid div {
  padding: 8px 24px;
  text-align: left;
}

.grid div {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
}

.grid li {
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
}

.left {
  flex: 1;
}

.form {
  margin: 12px 0 48px;
  padding: 6px 8px;
  background: #161616;
  border-radius: 30px;
  width: fit-content;
  display: flex;
  width: 100%;
  max-width: 490px;
}

.form button {
  padding: 8px 40px;
  border-radius: 24px;
  height: 48px;
  font-weight: 700;
  border: none;
  cursor: pointer;
  font-size: 20px;
  color: #fff;
  background: linear-gradient(90deg, #00fe7e 0%, #00c4fe 100%);
}

.form input {
  padding: 12px 24px;
  border-radius: 24px;
  background: #161616;
  border: none;
  color: white;
  font-size: 18px;
  flex: 1;
  width: 0;
}

.copy {
  border-top: 1px solid var(--line-dark);
  padding: 48px 0;
  text-align: center;
  color: #929394;
}

@media only screen and (max-width: 768px) {
  .footer {
    padding: 0 16px;
  }
  .ctn {
    flex-direction: column;
    margin-bottom: 0;
  }

  .left {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
  }

  .right {
    flex-direction: row !important;
    flex-wrap: wrap;
    justify-content: center;
    margin-inline: 0;
  }

  .grid {
    margin: 0;
    margin-bottom: 24px;
    width: 50%;
  }

  .grid div {
    font-weight: 700;
    font-size: 16px;
    line-height: 160%;
  }

  .grid li {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
  }

  .copy {
    padding: 24px 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1440px) {
  .footer {
    padding: 0 80px;
  }
}
