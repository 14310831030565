.outline {
  padding: 32px 40px;
  border-radius: 24px;
  border: 1px solid transparent;
  background-clip: padding-box;
  position: relative;
  background-color: #000;
}

.outline:before {
  content: '';
  position: absolute;
  inset: 0;
  z-index: -1;
  margin: -1px;
  background-image: linear-gradient(90deg, #00fe7e 0%, #00c4fe 100%);
  border-radius: inherit;
}

.primary {
  padding: 24px;
  border-radius: 24px;
  background-color: #161616;
  text-align: left;
  position: relative;
}

.title {
  margin-top: 24px;
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
}

.content {
  list-style-type: none;
  color: #929394;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
}

.content li {
  margin-bottom: 12px;
}

.link {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  bottom: 24px;
  right: 24px;
  left: 24px;
}

.link span {
  margin-right: 8px;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  background: var(--linear-primary);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.secondary {
  padding: 40px;
  border-radius: 24px;
  background-color: #161616;
  text-align: left;
}

.secondaryTitle {
  margin-bottom: 24px;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  color: #d2a549;
}

.secondaryContent {
  list-style-type: none;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 34px;
  color: #929394;
}

@media only screen and (max-width: 768px) {
  .primary {
    padding: 16px;
    border-radius: 16px;
  }

  .title {
    font-size: 20px;
    line-height: 24px;
  }

  .content {
    font-size: 16px;
    line-height: 20px;
  }

  .content li {
    margin-bottom: 8px;
  }

  .link {
    bottom: 16px;
    right: 16px;
    left: 16px;
    justify-content: flex-start;
  }

  .link span {
    font-size: 18px;
    line-height: 22px;
  }

  .secondary {
    padding: 16px;
  }

  .secondaryTitle {
    font-size: 18px;
    line-height: 22px;
  }

  .secondaryContent {
    font-size: 16px;
    line-height: 20px;
  }
}
