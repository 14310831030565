.ctn {
  margin-top: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background-image: url('../../assets/images/wave-dot.svg');
  background-repeat: no-repeat;
  background-position: center; */
  padding: 0 240px 80px;
}
.title {
  font-family: 'Bigbang';
  font-size: 56px;
  line-height: 56px;
  margin-top: 40px;
  letter-spacing: 0.1em;
  text-align: center;
}
.subTitle {
  font-weight: 600;
  font-size: 24px;
  line-height: 34px;
  text-align: center;
  letter-spacing: 0.1em;
  margin-top: 40px;
  color: #bababa;
  padding: 0 70px;
}
.groupBtn {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

@media only screen and (max-width: 768px) {
  .ctn {
    margin-top: 40px;
    padding: 0 16px;
  }

  .title {
    font-size: 18px;
    line-height: 18px;
    margin-top: 16px;
  }

  .subTitle {
    margin-top: 24px;
    font-size: 14px;
    line-height: 16px;
    padding: 0;
  }

  .groupBtn {
    margin-top: 24px;
    flex-direction: column;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1440px) {
  .ctn {
    padding: 0 80px;
  }
}
