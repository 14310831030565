:root {
  --linear: linear-gradient(
    310.41deg,
    #813900 14.54%,
    #ffc841 34.53%,
    #fff8a3 53.21%,
    #fbb040 70.16%,
    #c55c18 86.23%
  );
  --linear-primary: linear-gradient(90deg, #00fe7e 0%, #00c4fe 100%);
  --line-light: #e5e8eb;
  --content-light: #3c3c3c;
  --line-dark: #323232;
  --content-dark: #929394;
  --back-ground: #161616;
  --back-ground-linear: linear-gradient(
    90deg,
    rgba(0, 254, 126, 0.06) 0%,
    rgba(0, 196, 254, 0.06) 100%
  );
  --background-light: #f5f5f5;
  --back-ground-linear-light: linear-gradient(
    90deg,
    rgba(0, 254, 126, 0.26) 0%,
    rgba(0, 196, 254, 0.26) 100%
  );
}

@font-face {
  font-family: 'BigBang';
  src: url('./assets/fonts/BigBang.ttf') format('truetype');
}

@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/Gilroy-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/Gilroy-Bold.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/Gilroy-SemiBold.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/Gilroy-Medium.ttf') format('truetype');
  font-weight: 500;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  outline: none;
}

body {
  background-color: #000;
  color: #fff;
  font-size: 18px;
  font-family: 'Gilroy';
  background-image: url('./assets/images/Home.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

a {
  text-decoration: none;
}

a,
a:visited,
a:hover,
a:active {
  color: inherit;
}

.d-flex {
  display: flex;
}

.d-flex-column {
  display: flex;
  flex-direction: column;
}

.align-items-center {
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}

.space-between {
  justify-content: space-between;
}

.text-center {
  text-align: center;
}

.w-66 {
  width: 66%;
}

.w-70 {
  width: 70%;
}

.w-50 {
  width: 50%;
}

.w-30 {
  width: 30%;
}

.w-33 {
  width: 33%;
}

.mb-8 {
  margin-bottom: 8px;
}

.mb-24 {
  margin-bottom: 24px;
}

.mb-40 {
  margin-bottom: 40px;
}

.m-20 {
  margin: 20px;
}

.m--20 {
  margin: -20px;
}

.p-54 {
  padding: 54px;
}

.gradient-text {
  background: var(--linear-primary);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 32px;
  line-height: 39px;
}

.expand {
  display: block !important;
}

.center {
  margin: 0 auto 40px;
}

.big-font {
  font-size: 48px;
  line-height: 58px;
}

@media only screen and (max-width: 768px) {
  body {
    font-size: 16px;
  }

  .d-flex {
    flex-direction: column;
  }

  .w-70,
  .w-50,
  .w-33,
  .w-30 {
    width: 100%;
  }

  .m-20,
  .m--20 {
    margin: 0px;
  }

  .m-20 {
    margin-block: 8px;
  }

  .p-54 {
    padding: 0;
  }

  .big-font {
    font-size: 24px;
    line-height: 29px;
  }
}

h2 {
  width: fit-content;
  font-weight: 700;
}

h3 {
  font-weight: 500;
}

#testnet-link {
  position: absolute;
  bottom: 8px;
  left: 50%;
  transform: translateX(-50%);
  background: var(--linear-primary);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
}
