.content {
  padding: 116px 240px 120px;
}

.intro {
  text-align: center;
  padding: 0 140px;
}

.intro p {
  color: #bababa;
  font-size: 24px;
  line-height: 34px;
  font-weight: 500;
}

.rrs {
  margin-top: 80px;
  padding-top: 40px;
}

.rrs .details {
  padding: 80px 0 80px 120px;
}

.feature,
.showcase {
  margin-top: 140px;
  text-align: center;
  padding-top: 40px;
}

.partners {
  display: flex;
  height: 160px;
  padding-block: 40px;
  justify-content: center;
  overflow: auto;
  background: linear-gradient(
    90deg,
    rgba(0, 254, 126, 0.06) 0%,
    rgba(0, 196, 254, 0.06) 100%
  );
}

.gradientBorder {
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  background-clip: padding-box;
  background: linear-gradient(#000000, #000000) padding-box fixed,
    linear-gradient(90deg, #00fe7e 0%, #00c4fe 100%) border-box;
}

.partners div {
  margin-inline: 40px;
}

.partners img {
  height: 100%;
}

@media only screen and (max-width: 768px) {
  .content {
    padding: 116px 16px 120px;
  }

  .rrs .details {
    padding: 0;
  }

  .rrs .details h2,
  .rrs .details h3 {
    text-align: center;
  }

  .intro {
    padding: 0;
  }

  .intro p {
    font-size: 14px;
    line-height: 16px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1440px) {
  .content {
    padding: 116px 80px 120px;
  }
}
