.showcase {
  margin-top: 140px;
  text-align: center;
  padding-top: 40px;
  margin-bottom: 200px;
}

.partners {
  display: flex;
  height: 160px;
  padding-block: 40px;
  justify-content: center;
  overflow: auto;
  background: linear-gradient(
    90deg,
    rgba(0, 254, 126, 0.06) 0%,
    rgba(0, 196, 254, 0.06) 100%
  );
}

.gradientBorder {
  margin-top: 60px;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  background-clip: padding-box;
  background: linear-gradient(#000000, #000000) padding-box fixed,
    linear-gradient(90deg, #00fe7e 0%, #00c4fe 100%) border-box;
}

.partners div {
  margin-inline: 40px;
}

.partners img {
  height: 100%;
}

@media only screen and (max-width: 768px) {
  .showcase {
    margin-top: 80px;
    margin-bottom: 40px;
  }

  .partners {
    height: 88px;
    padding-block: 24px;
  }

  .gradientBorder {
    margin-top: 0;
  }

  .partners div {
    margin-inline: 12px;
  }
}
