.ctn {
  padding: 40px 80px;
  display: flex;
  align-items: center;
  border-radius: 32px;
  background: #161616;
  margin-bottom: 40px;
}

.content,
.video {
  width: 50%;
}

.video {
  border: 2px solid #d2a549;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  text-align: left;
  margin-right: 80px;
}

.title {
  color: #d2a549;
  font-size: 32px;
  line-height: 39px;
  font-weight: 700;
}

.subTitle {
  font-size: 24px;
  line-height: 34px;
  color: #929394;
}

@media only screen and (max-width: 768px) {
  .ctn {
    flex-direction: column;
    padding: 16px;
    margin-bottom: 8px;
  }

  .content,
  .video {
    width: 100%;
  }

  .content {
    margin: 0 0 24px 0;
  }

  .title {
    font-size: 18px;
    line-height: 22px;
  }

  .subTitle {
    font-size: 16px;
    line-height: 20px;
  }
}
