.model {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.5);
}

.popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #161616;
  padding: 80px 120px;
  border-radius: 24px;
}

.popupIcon {
  margin-bottom: 24px;
}

.popupTitle {
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
}

.popupSubTitle {
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  margin: 8px 0 24px;
  white-space: nowrap;
}

@media only screen and (max-width: 768px) {
  .popup {
    padding: 16px;
  }
}
