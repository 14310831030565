.ctn {
  padding: 8px 40px;
  border-radius: 24px;
  height: 48px;
  font-weight: 700;
  border: none;
  cursor: pointer;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
}

.primary {
  color: #fff;
  background: var(--linear-primary);
}

.outline {
  border: 2px solid transparent;
  position: relative;
  background: var(--linear-primary);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
  border: none;
  position: relative;
  z-index: 0;
}

.outline::before {
  content: '';
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 24px;
  border: 2px solid transparent;
  background: inherit;
  background-origin: border-box;
  background-clip: border-box;
  -webkit-mask: var(--linear-primary) padding-box, var(--linear-primary);
  mask: var(--linear-primary) padding-box, var(--linear-primary);
  -webkit-mask-composite: source-out;
  mask-composite: exclude;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

.outline span {
  background: var(--linear-primary);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
